<template>
  <div v-if="patient" class="row">
    <div
      :class="!staticGrid ? 'col-auto col-lg-12' : 'col-12'"
      class="d-flex justify-content-center"
    >
      <div class="d-inline-block img-thumbnail mb-3">
        <b-avatar
          :variant="patient.contentId ? 'white' : 'info'"
          :text="abbreviation"
          alt="User Image"
          size="120px"
          :src="viewFile(patient.contentId)"
          square
          rounded
        ></b-avatar>
      </div>
    </div>
    <div :class="!staticGrid ? 'col-4 col-lg-12' : 'col-12'">
      <p class="mb-0 narrow-spacing">
        DN: {{ patient.dn }} <CopyToClipboard :text="patient.dn" />
      </p>
      <p
        v-if="patient.existDn"
        class="mb-2 text-muted text-bold small-text narrow-spacing"
      >
        DN(เดิม): {{ patient.existDn }}
        <CopyToClipboard :text="patient.existDn" />
      </p>
      <div :class="!staticGrid ? 'd-block d-lg-none' : ''">
        <p class="mb-0 text-bold">{{ fullName }}</p>
        <p class="mb-2" v-if="patient.dob">
          อายุ: {{ calAge(patient.dob) }} ปี
        </p>
      </div>

      <div :class="!staticGrid ? 'd-none d-lg-block' : ''">
        <p v-if="patient.titleNameTh" class="mb-0">{{ patient.titleNameTh }}</p>
        <p class="mb-0 text-bold">{{ patient.firstNameTh }}</p>
        <p class="mb-0 text-bold">{{ patient.lastNameTh }}</p>
        <p class="mb-2" v-if="patient.dob">
          อายุ: {{ calAge(patient.dob) }} ปี
        </p>
      </div>
      <p v-if="patient.nickName" class="mb-2">({{ patient.nickName }})</p>
      <hr class="my-1" :class="!staticGrid ? 'd-none d-lg-block' : ''" />
      <div v-if="isManagerOrCounter">
        <p class="mb-2">โทร: {{ formatMobileNumber(patient.mobile) }}</p>
        <hr class="my-1" :class="!staticGrid ? 'd-none d-lg-block' : ''" />
      </div>
      <p v-if="isPatientDoctor" class="mb-2 narrow-spacing">
        แพทย์ประจำ: {{ getDoctorListBy(patient.doctorId, "uid").fullName }}
      </p>
    </div>
    <div :class="!staticGrid ? 'col-5 col-lg-12' : 'col-12'">
      <p class="text-danger mb-2">
        โรคประจำตัว: {{ patient.congenitalDisease }}
      </p>
      <p class="text-danger mb-2">แพ้: {{ patient.allergic }}</p>
      <p v-if="patient.note" class="mb-2">โน๊ต: {{ patient.note }}</p>
      <b-button v-if="buttonName" class="mb-3" block @click="buttonClick">
        {{ buttonName }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import CopyToClipboard from "./CopyToClipboard.vue";

export default {
  name: "PatientProfile",
  props: {
    patient: Object,
    buttonName: String,
    buttonClick: Function,
    staticGrid: Boolean,
  },
  components: { CopyToClipboard },
  computed: {
    ...mapGetters({
      viewFile: "moduleContent/viewFile",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getDoctorListBy: "moduleUser/getDoctorListBy",
    }),
    fullName() {
      if (this.patient) {
        var tilte = this.patient.titleNameTh
          ? this.patient.titleNameTh
          : this.patient.titleNameEn
          ? this.patient.titleNameEn
          : "";
        var firstName = this.patient.firstNameTh
          ? this.patient.firstNameTh
          : this.patient.firstNameEn
          ? this.patient.firstNameEn
          : "";
        var lastName = this.patient.lastNameTh
          ? this.patient.lastNameTh
          : this.patient.lastNameEn
          ? this.patient.lastNameEn
          : "";
        var fullName = [tilte, firstName, lastName].join(" ");
        return fullName;
      } else {
        return "";
      }
    },
    abbreviation() {
      if (
        this.patient &&
        (this.patient.firstNameEn || this.patient.lastNameEn)
      ) {
        var firstName = "";
        var lastName = "";
        if (this.patient.firstNameEn) {
          firstName = this.patient.firstNameEn.charAt(0);
        }
        if (this.patient.lastNameEn) {
          lastName = this.patient.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return "";
      }
    },

    isManagerOrCounter() {
      return this.isAccessMenu(["MANAGER", "COUNTER", "HEADCOUNTER"]);
    },
    isPatientDoctor() {
      return (
        this.patient?.doctorId &&
        this.getDoctorListBy(this.patient.doctorId, "uid")
      );
    },
  },
  methods: {
    formatMobileNumber(num) {
      if (num) {
        return num.replace(/(\d{1,3})(\d{1,3})?(\d{1,10})?/g, "$1-$2-$3");
      }
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>

<style></style>
